/*!

 =========================================================
 * Material Dashboard React - v1.6.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react-nodejs/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label, .ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #fafafa;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

blockquote footer:before, blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
  padding-left: 20px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body, h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
  cursor: pointer;
}

a:hover, a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

a:focus, a:active, button:active, button:focus, button:hover, button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, select::-moz-focus-inner, input[type="file"]>input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 500;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  body, html {
    position: relative;
    overflow-x: hidden;
  }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}

.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
}

/* .badge-primary-background-color: #9c27b0;
} */

.fixed-plugin .SocialMediaShareButton, .fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li>a, .fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu>.active>a, .fixed-plugin .dropdown-menu>.active>a:hover, .fixed-plugin .dropdown-menu>.active>a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li>a:hover, .fixed-plugin .dropdown-menu li>a:focus {
  box-shadow: none;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active, .fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}

.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line, .fixed-plugin li.header-title, .fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div, .fixed-plugin li.header-title div, .fixed-plugin li.button-container div {
  margin-bottom: 5px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover, .fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}

.fixed-plugin .adjustments-line .dropdown-menu>li.adjustments-line>a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder img {
  margin-top: auto;
}

.fixed-plugin .dropdown-menu>li:hover>a.img-holder, .fixed-plugin .dropdown-menu>li:focus>a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu>.active>a.img-holder, .fixed-plugin .dropdown-menu>.active>a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  -moz-transform: translateY(-15%);
  -o-transform: translateY(-15%);
  -ms-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}

.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before, .fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  -moz-transform: translateY(-13%);
  -o-transform: translateY(-13%);
  -ms-transform: translateY(-13%);
  transform: translateY(-13%);
  transform-origin: 0 0;
}

.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}

.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}

.fixed-plugin .github-btn {
  font: bold 11px/14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 20px;
  overflow: hidden;
}

.fixed-plugin .gh-btn, .fixed-plugin .gh-count, .fixed-plugin .gh-ico {
  float: left;
}

.fixed-plugin .gh-btn, .fixed-plugin .gh-count {
  padding: 2px 5px 2px 4px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
}

.fixed-plugin .gh-btn {
  background-color: #eee;
  background-image: -webkit-gradient( linear, left top, left bottom, color-stop(0, #fcfcfc), color-stop(100%, #eee));
  background-image: -webkit-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -moz-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -ms-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -o-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
  background-repeat: no-repeat;
  border: 1px solid #d5d5d5;
}

.fixed-plugin .gh-btn:hover, .fixed-plugin .gh-btn:focus {
  text-decoration: none;
  background-color: #ddd;
  background-image: -webkit-gradient( linear, left top, left bottom, color-stop(0, #eee), color-stop(100%, #ddd));
  background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -moz-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -ms-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -o-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
  border-color: #ccc;
}

.fixed-plugin .gh-btn:active {
  background-image: none;
  background-color: #dcdcdc;
  border-color: #b5b5b5;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}

.fixed-plugin .gh-ico {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.fixed-plugin .gh-count {
  position: relative;
  display: none;
  /* hidden to start */
  margin-left: 4px;
  background-color: #fafafa;
  border: 1px solid #d4d4d4;
}

.fixed-plugin .gh-count:hover, .fixed-plugin .gh-count:focus {
  color: #4183c4;
}

.fixed-plugin .gh-count:before, .fixed-plugin .gh-count:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.fixed-plugin .gh-count:before {
  top: 50%;
  left: -3px;
  margin-top: -4px;
  border-width: 4px 4px 4px 0;
  border-right-color: #fafafa;
}

.CallingCard {
  min-height: 320px;
  margin-top: 0 !important;
}

.CallingCard .MuiAccordionSummary-content, .CallingCard .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.CallingCard .MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px;
}

.CallingCard h4 {
  margin: 5px 0;
  text-transform: capitalize;
  font-weight: 400;
}

.CallingCard h4 button {
  float: right;
}

.CallingCard h4 button a {
  color: #fff;
}

.CallingCard ul {
  margin: 5px;
  list-style: none;
  padding: 0;
  width: 100%;
}

.CallingCard ul li {
  width: 100%;
  display: inline-block;
  margin: 4px 0;
}

.CallingCard ul li b {
  width: 40%;
  display: inline-block;
}

.CallingCard ul li em {
  width: 55%;
  font-style: normal;
  display: inline-block;
  font-weight: 400;
}

.CallingCard ul li a {
  padding: 5px 10px;
  background-color: #1ea02d;
  color: #ffffff;
  font-size: 16px;
  border-radius: 5px;
  font-weight: bold;
}

.dispositions {
  padding-left: 20px !important;
  margin-top: 5px;
}

#frmDisposition {
  max-height: 270px;
  overflow-x: hidden;
  overflow-y: auto;
}

.hide {
  display: none;
}

.active {
  display: block;
}

.whatsapp {
  background-color: #e6d6d6 !important;
  color: #0a6d0a !important;
  font-size: 18px !important;
  padding: 3px;
}

.email a {
  padding: 0 !important;
  background-color: #ffffff !important;
  color: #3f51b5 !important;
  font-size: 14px !important;
}

.date-range {
  position: fixed !important;
  z-index: 99 !important;
  top: 25vh;
  left: 25vw;
}

.rdt_TableCell {
  word-break: normal !important;
}

.sidebarmenu {
  height: 100% !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.sidebarmenu::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
}

.sidebarmenu::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

.sidebarmenu::-webkit-scrollbar-thumb {
  background-color: rgb(160, 160, 182);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  /* creates padding around scroll thumb */
}

@media only screen and (max-width: 600px) {
  .date-range {
    margin: auto;
    position: absolute !important;
    z-index: 99 !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .date-range .MuiGrid-wrap-xs-nowrap {
    flex-wrap: wrap;
  }
}

.App-con {
  text-align: center;
}

.channel-wrapper {
  border: 1px solid #d0dbe3;
  padding: 20px 15px;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 9%);
  margin-bottom: 1rem;
}

.channel-wrapper .img-responsive {
  margin: 0 auto;
  max-width: 100%;
  max-height: 35px;
}

.channel-wrapper h4 {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.channel-wrapper a {
  padding: 5px 15px;
  border-radius: 14px;
  background: #285fdb;
  color: #fff;
  transition: all 0.1s ease;
}

.channel-wrapper a {
  opacity: 0.9;
}

@media only screen and (max-width: 600px) {
  .channel-wrapper {
    margin-bottom: 1rem;
  }
}

/* dispositions-label */

.dispositions-label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}

.dispositions-label input {
  position: absolute;
  left: -9999px;
}

.dispositions-label label {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 99em;
  transition: 0.25s ease;
}

.dispositions-label label:hover {
  background-color: #d6d6e5;
}

.dispositions-label input:checked+label {
  background-color: #d6d6e5;
}

.dispositions-label label:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #00acc1;
}

.dispositions-label input:checked+label:before {
  box-shadow: inset 0 0 0 0.4375em #00acc1;
}

.maxHeight300 {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}

.scroller::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
}

.scroller::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

.scroller::-webkit-scrollbar-thumb {
  background-color: rgb(160, 160, 182);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  /* creates padding around scroll thumb */
}

/* campaigns page */

.campaigns-wrap .campaigns-sta-red {
  color: rgba(157, 23, 77)!important;
  background-color: rgba(252, 231, 243) !important;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-weight: 600;
  border-radius: 9999px;
  font-size: 0.85rem;
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 14px;
}

.campaigns-wrap .campaigns-sta {
  background-color: rgba(220, 252, 231);
  color: rgba(22, 101, 52);
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-weight: 600;
  border-radius: 9999px;
  font-size: 0.85rem;
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 14px;
}

.campaigns-wrap p.campaigns-title {
  margin: 0;
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
}

.border-t-2 {
  border-top-width: 2px !important;
  width: 2rem !important;
  height: 0.25rem !important;
  margin-top: 0.25rem !important;
  margin-bottom: 0.5rem !important;
  color: rgb(226, 232, 240);
  border-top: 2px solid;
}

.campaigns-item {
  padding: 0 10px;
}

.campaigns-wrap .campaigns-item span {
  color: #64748b;
  font-size: 0.8rem;
  font-weight: 400;
}

.campaigns-wrap .campaigns-footer {
  background-color: rgba(248, 250, 252);
  border-top-width: 2px !important;
  color: rgb(226, 232, 240);
  border-top: 2px solid;
  padding: 28px;
  margin: 0;
}

.campaigns-wrap .campaigns-footer a {
  border-color: rgba(203, 213, 225);
  cursor: pointer;
  padding: 3px 15px !important;
  border-radius: 9999px !important;
  color: #1e293b;
  border: 1px;
  border-width: 4px;
  border: 2px solid rgba(203, 213, 225);
  font-weight: 400;
  font-size: 0.8rem;
}

.campaigns-actions {
  padding-top: 10px;
  display: flex;
}

.campaigns-actions a {
  color: #64748b;
}

.campaigns-actions a i {
  padding: 9px 10px;
  color: #64748b;
  border-radius: 50%;
  border: 0.2px solid #64748b;
  margin-right: 10px;
  margin-top: 0.7rem;
}

.campaigns-actions a i:hover {
  background-color: #64748b;
  color: #fff;
}

.MuiSnackbar-anchorOriginTopRight{
  top: 75px !important;
}

.datatableresponsive 
{
    margin-top: 15px !important;
    border-radius: 0 !important;
    box-shadow: 0px 1px 5px 2px rgb(0 0 0 / 14%) !important;
    padding: 8px 0;

}
.datatableresponsive .cardbody{
  padding: 0 !important;
}
.datatableresponsive .cardfooter{
  padding: 0 5px !important;
  margin: 0 !important;
}
.datatableresponsive ul{
  list-style: none;
  padding: 0px 10px;
}
.datatableresponsive ul li{
  width: 100%;
  display: inline-block;
}
.datatableresponsive label{
  color: #000000;
    width: 100%;
    display: inline-block;

}
.datatableresponsive span{
  color: #000000;
   
    display: flex;

}
.datatableresponsive .customerName
{ 
  font-size: medium;
}
.datatableresponsive .custPhone
{ 
  font-size: smaller;
  width: 100%;
  display: block;
  line-height: 10px;
}
.datatableresponsive .lastUpdated
{ 
  font-style: italic;
    font-size: x-small;
    opacity: 0.7;
    display: block;
}
.datatableresponsive .lastDisposition
{ 
  
  font-size: 0.7rem;
    display: block;
    line-height: 15px;
}

.ProfileSection{
  .MuiGrid-item{
    text-align: left;
  }
}

.actimeline{
  padding: 0 !important;
  margin: 0 !important;
  li{
    margin: 1px 0 !important;
  }
  li b{
    width: 100% !important;
  }
  li em{
    width: 100% !important;
  }
}