.propspect__table > .jPffGe {
  overflow-y: auto;
  height: 500px;
}

.crmurl{
  width: 100%;
  height: 70em;
  border: 0;
  background: transparent;
}