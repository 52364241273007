.ViewDisposition-disposition-154 {
  margin: 6px 0px !important;
}

.ViewDisposition-dispositionElement-156 {
  background: "#f2edd7" !important;
}

.dispositions * {
  /* margin: 6px 0px !important; */
}

.CardHeader-cardHeader-133 > h3 {
  margin-top: -3px !important;
}

.dispositions {
  margin-top: -10px;
}
