.search__bar{
  float: right;
}
@media screen and (max-width: 600px) {
  /* .search__bar {
    display: none !important;
  } */
}
.navbar__search .MuiInputBase-input.MuiInput-input {
  color: white !important;
}

.dialer svg{
  font-size: 53px;
  background: #44c668;
  border-radius: 100%;
  padding: 12px;
  margin: 10px;
  box-shadow: 0 0 10px #44c668;
  position: absolute;
  top: 69px;
  right: 4px;
}
