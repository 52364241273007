@media screen and (max-width: 600px) {
  .MuiButtonBase-root.MuiButton-root.MuiButton-text.RegularButton-button-54.RegularButton-sm-66.RegularButton-transparent-63 {
    display: none !important;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-text.RegularButton-button-54.RegularButton-white-55.RegularButton-simple-62.HeaderLinks-buttonLink-71 {
    display: none !important;
  }

  /* .Sidebar-sidebarWrapper-27 > div {
    display: none !important;
  } */
}

.sidebar__link:hover {
  color: green !important;
}

.sidebar {
  transition: all 200ms ease-out !important;
  cursor: pointer;
}
.sidebar + div {
  cursor: pointer;
}

.sidebar:hover {
  background: #d1c3c329;
}

.sidebar > div :hover {
  /* transform: scale(1.1) !important; */
  cursor: pointer;
}
