#dialpadcss span input {
    width: 93%;
    padding: 24px;
    margin: 15px;
    font-size: 25px;
}
.phoneIcon{
  font-size: 46px;
  background: #44c668;
  border-radius: 6%;
  padding: 18px;
  margin: 0;
  color: green;
  box-shadow: 0 0 10px #44c668;
  width: 99%;
}

.firstinput{
  display:flex;
}
.call{
  width:100% !important;
}

.back {
    font-size: 3rem;
}

.row {
    margin: 0 auto;
    width: 100%;
    clear: both;
    text-align: center;
    font-family: 'Exo';
  }
  
  .digit,
  .dig {
    float: left;
    padding: 25px 30px;
    width: 33%;
    font-size: 4rem;
    cursor: pointer;
  }
  
  .sub {
    font-size: 0.8rem;
    color: grey;
  }
  
  body {
    background-color:white !important;
  }
  .container {
    position: absolute;
    background-color: white;
    width: 99%;
    top: 30%;
    border: 0px;
    margin: 0 auto;
    text-align: center;
  }
  
  #output {
    font-family: "Exo";
    font-size: 3rem;
    height: 60px;
    font-weight: bold;
    color: #1976d2;
    width: 80%;
  
    
  }
  
  #call {
    display: inline-block;
    background-color: #66bb6a;
    padding: 4px 30px;
    margin: 10px;
    color: white;
    border-radius: 4px;
    float: left;
    cursor: pointer;
  }
  
  .botrow {
    margin: 0 auto;
    width: 280px;
    clear: both;
    text-align: center;
    font-family: 'Exo';
  }
  
  .digit:active,
  .dig:active {
    background-color: #e6e6e6;
  }
  
  #call:hover {
    background-color: #81c784;
  }
  
  .dig {
    float: left;
    padding: 10px 20px;
    margin: 10px;
    width: 33%;
    cursor: pointer;
  }